import React, { useState } from 'react';
import styled from 'styled-components';

import { A, Popover, DeleteConfirmPopContent } from 'atoms';
import { FaThumbtack } from 'icons';

const PinA = styled(A)`
  font-size: 16px;
  color: var(--color-grey);
`;

const PinCta = ({
  onPin,
  placement,
  title,
  text,
  loading,
  pinContent,
  children,
  showNo = true
}) =>
{
  const [pinPopoverVisible, setPinPopoverVisible] = useState(false);
  const handlePin = () =>
  {
    onPin();
    setPinPopoverVisible(false)
  }

  return (
    <Popover
      placement={placement}
      title={title}
      zIndex={1600}
      content={
        <DeleteConfirmPopContent
          text={text}
          onNo={() => setPinPopoverVisible(false)}
          onYes={handlePin}
          loading={loading}
          showNo={showNo}
        />}
      trigger={'click'}
      open={pinPopoverVisible}
      onOpenChange={visible => setPinPopoverVisible(visible)}
    >
      {pinContent || children}
    </Popover>
  )
};

PinCta.defaultProps = {
  placement: 'top',
  title: 'Are you sure?',
  text: `This will replace any current pinned posts.`,
  pinContent: null,
  children: <PinA><FaThumbtack/></PinA>
}

export default PinCta;
