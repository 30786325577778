import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper";

SwiperCore.use([Navigation, Pagination, Thumbs]);

export const StyledSwiper = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    color: #000;
    background-color: #ffffffb0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 10px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: rgba(229, 226, 226, 0.7);
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--color-primary) !important;
  }
`;

export { Swiper, SwiperSlide, SwiperCore };
