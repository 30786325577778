import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { useHowler } from 'libraries/howler';
import {
  RecordRow,
  VisualizationContainer,
  RecordVisualization,
  RecordCTAContainer,
  PlayButton,
  RecordCTAButton,
  LockButton,
} from './common';
import { INITIAL, PLAYING } from 'libraries/Audio/const';
import { Flex,Slider } from 'antd';
import { FaLock } from 'icons';
import { Span,Col} from 'atoms';
import { hasTwoDigit } from 'utils/Datetime';


export const PauseButton = (props) => (
  <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPause} />} {...props} />
);

const StyledPreviewPlayer = styled.div``;

const AudioPlayContainer = styled.div`
  width: 100%;
  padding: 15px 0 15px 15px;
`;
const AudioDuration = styled.div`
  color: white;
  ${(props) =>
    props.singleRow &&
    `
        width: auto;
        text-align: right;
    `}
`;
const PodSlider = styled(Slider)`
  .ant-slider-track {
    background-color: ${(props) => `${props.theme.colors.primary}`};
  }
  .ant-slider-handle {
    background-color: var(--color-primary);
    :after {
      background-color: var(--color-primary);
      box-shadow: 0 0 0 2px white;
    }
  }
  &.ant-slider .ant-slider-rail {
    background-color: white;
  }
  &:hover {
    .ant-slider-track {
      background-color: ${(props) => `${props.theme.colors.primary}`};
    }
    .ant-slider-handle:not(.ant-tooltip-open) {
      background-color: var(--color-primary-hover);
      :after {
        box-shadow: 0 0 0 2px var(--color-primary-hover);
      }
    }
  }
`;

const rateOptions = [0.8, 1, 1.2, 1.5, 1.8, 2];
const EpisodeDetailSlider = ({ simple = false, src, duration , showSubscribeMessage = true}) => {
  const { isMobile } = useContext(ThemeContext);
const [playRate, setPlayRate] = useState(1);
const { playing, paused, loaded, loading, play, idle, pause, seekPercent, seekTo,  mm, ss, forward, backward } = useHowler({
    src,
    duration,
    rate: playRate
  });

  const onSliderChange = (v) => {
    seekTo((duration * v) / 100);
  }

  
  const tmm = useMemo(() => hasTwoDigit(Math.floor(duration / 60)), [duration]);
  const tss = useMemo(() => hasTwoDigit(Math.floor(duration % 60)), [duration]);

  return (
    <StyledPreviewPlayer>
        <div style={{width:'100%'}}>
          <PodSlider
            style={{display:'flex',justifyContent:'center', marginTop: '20px'}}
            defaultValue={0}
            value={seekPercent}
            step={0.01}
            onChange={onSliderChange}
            tooltip={{ open: false }}
          />
        </div>
        <AudioDuration
          singleRow={true}
          style={{ display: "flex", justifyContent: "space-between" ,fontSize:'12px', }}>
          <div>{idle ? '00:00' : `${mm}:${ss}`}</div>
          <div>{tmm}:{tss}</div>  
        </AudioDuration>
      <Col style={{display:'flex',justifyContent:'center'}}>
        <RecordCTAContainer>
          <PlayButton css={`width: 40px; height: 40px; border-radius: 5px; --color: #d9d9d9;`} />
          <LockButton />
        </RecordCTAContainer>
      </Col>
    {showSubscribeMessage && (

      <RecordRow>
        <VisualizationContainer css={`margin-right: 0;`}>
          <RecordVisualization css={`border: 0;`}>
            <AudioPlayContainer>
              <Flex align='center' gap={10}>
                <Span color='grey' css="margin-right: auto;">Subscribe to unlock this episode</Span>
                
              </Flex>
            </AudioPlayContainer>
          </RecordVisualization>
        </VisualizationContainer>
      </RecordRow>
    )}
    </StyledPreviewPlayer>
  );
};

export default EpisodeDetailSlider;








