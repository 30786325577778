export const sortCompare = (a, b) => {
    if (typeof a == "boolean") {
        const map = new Map();
        map.set(true, 1);
        map.set(false, 0);
        return map.get(a) - map.get(b);
    }
    return a > b ? 1 : a === b ? 0 : -1;
};

export const arrayMove = (arr, oldIndex, newIndex) => {
    const _arr = [...arr];
    const oldItem = _arr[oldIndex];
    _arr.splice(oldIndex, 1);
    if (newIndex > oldIndex) {
        _arr.splice(newIndex, 0, oldItem);
    } else {
        _arr.splice(newIndex, 0, oldItem);
    }
    return _arr;
}
