export { sortCompare, arrayMove } from './Array';

export function isElementInViewport(el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while(el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < (window.scrollY + window.innerHeight) &&
    left < (window.scrollX + window.innerWidth) &&
    (top + height) > window.scrollY &&
    (left + width) > window.scrollX
  );
}
