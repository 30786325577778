import React, { useState } from 'react';
import styled from 'styled-components';

import { A, Popover, DeleteConfirmPopContent } from 'atoms';
import { FaTrashAlt } from 'icons';

const DeleteA = styled(A)`
  font-size: 16px;
  color: var(--color-grey);
`;

const DeleteCta = ({
  onDelete,
  placement,
  title,
  text,
  loading,
  deleteContent,
  children,
  closeAfterDelete = true,
  showNo = true
}) =>
{
  const [removePopoverVisible, setRemovePopoverVisible] = useState(false);
  const handleDelete = () =>
  {
    onDelete();
    if(closeAfterDelete){
      setRemovePopoverVisible(false)
    }
  }

  return (
    <Popover
      placement={placement}
      title={title}
      zIndex={1600}
      content={
        <DeleteConfirmPopContent
          text={text}
          onNo={() => setRemovePopoverVisible(false)}
          onYes={handleDelete}
          loading={loading}
          showNo={showNo}
        />}
      trigger={'click'}
      open={removePopoverVisible}
      onOpenChange={visible => setRemovePopoverVisible(visible)}
    >
      {deleteContent || children}
    </Popover>
  )
};

DeleteCta.defaultProps = {
  placement: 'top',
  title: 'Are you sure?',
  text: `Deleting this can't be undone.`,
  deleteContent: null,
  children: <DeleteA><FaTrashAlt/></DeleteA>
}

export default DeleteCta;
